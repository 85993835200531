import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Logo from "../../content/assets/optotax-wordmark.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebookSquare,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

const Layout = props => {
  const { title, children, currentLink } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  let hrefLink = "#"
  const linkClass = link =>
    link === currentLink ? "nav-item nav-current" : "nav-item"
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/react-tabs/style/react-tabs.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link rel="stylesheet" href="https://use.typekit.net/ptd5poe.css" />

        <script
          data-ad-client="ca-pub-6894001295523759"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={hrefLink}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>

          <Link className="site-head-logo" to={`/`}>
            <div className="logo-block">
              <img src={Logo} className="logo" alt="Optotax" />
            </div>
          </Link>
          <ul className="nav" role="menu">
            <li className={linkClass("/")} role="menuitem">
              <Link to={`/`} activeClassName="active-menu-link">
                Home
              </Link>
            </li>
            <li className={linkClass("/events")} role="menuitem">
              <Link to={`/events`} activeClassName="active-menu-link">
                Webinars
              </Link>
            </li>

            
<li className={linkClass("/blog")} role="menuitem">
              <Link to={`/blog`} activeClassName="active-menu-link">
Learning Forum              </Link>
            </li>

           

            <li className={linkClass("/faculty")} role="menuitem">
              <Link to={`/faculty`} activeClassName="active-menu-link">
                Faculty
              </Link>
            </li>

            <li className="nav-item" role="menuitem">
              <a
                href="https://gst.optotax.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                Login/Signup
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <article id="swup" className="transition-fade">
          {children}
        </article>
      </main>
      <footer className="site-foot">
        <div className="footer-grid">
          <div>
            <div id="support" className="text-center">
              <p className="footer-title">Support</p>
              <div className="footer-text">
                <p>
                  <a className="email-link" href="mailto:support@optotax.com">
                    support@optotax.com
                  </a>
                </p>
                <p>
                  <a className="email-link" href="tel:+918103810350">
                    +91 8103 8103 50
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="seperator-left">
            <p className="footer-title">Postal Address</p>
            <div>
              <p>
                Open Financial Technologies Private Limited (Hyderabad Unit)
                <br />
                CoWrks (First Floor), The Skyview, Sy no 83/1, 
                <br />
                Hitech City Main Rd, Raidurgam,
                <br />
                Hyderabad, Telangana 500081
              </p>
            </div>
          </div>
          <div className="seperator-left">
            <h6 className="footer-title high-res-only">Connect</h6>
            <div className="social-container">
              <div className="social-box">
                <a
                  href="http://facebook.com/optotaxgstsoftware"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </h2>{" "}
                  Facebook
                </a>
              </div>
              <div className="social-box">
                <a
                  href="http://twitter.com/OPTOTAX_GST"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faTwitter} />
                  </h2>{" "}
                  Twitter
                </a>
              </div>
              <div className="social-box">
                <a
                  href="https://www.linkedin.com/company/optotax"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </h2>{" "}
                  LinkedIn
                </a>
              </div>
              <div className="social-box">
                <a
                  href="https://www.instagram.com/optotax/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h2>
                    <FontAwesomeIcon icon={faInstagram} />
                  </h2>{" "}
                  Instagram
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          &copy; {new Date().getFullYear()} <Link to={`/`}>Optotax</Link>
        </div>
      </footer>
    </div>
  )
}

export default Layout
